import React from "react"
import { AspectRatio, Box } from "@chakra-ui/react"
import { Text, SEO } from "@src/shared/components"

const Home: React.FC = () => {
  return (
    <>
      <SEO />
      <Box maxWidth="1200px" margin="0 auto">
        <AspectRatio ratio={1}>
          <iframe
            title="naruto"
            src="https://stories.diatom-ui.dev/iframe.html?id=marketing-header-with-a-logo--template&args=&viewMode=docs"
            allowFullScreen
            allow="clipboard-read; clipboard-write"
          />
        </AspectRatio>
      </Box>
    </>
  )
}

export default Home
