import React from "react"
import { Box, BoxProps } from "@chakra-ui/react"
import {
  motion,
  Transition,
  AnimationControls,
  TargetAndTransition,
  VariantLabels,
} from "framer-motion"
import { useIsHovered } from "../../_context"

const MotionBox = motion(Box)

interface GroupHoverBaseProps {
  initial: boolean
  mouseEnter: {
    [key: string]:
      | AnimationControls
      | TargetAndTransition
      | VariantLabels
      | boolean
      | string
      | number
  }
  mouseLeave: {
    [key: string]:
      | AnimationControls
      | TargetAndTransition
      | VariantLabels
      | boolean
      | string
      | number
  }
  transition: {
    [key: string]: Transition | string | number
  }
}

type GroupHoverProps = BoxProps & GroupHoverBaseProps

export const GroupHover: React.FC<GroupHoverProps> = ({
  children,
  initial = false,
  transition,
  mouseEnter,
  mouseLeave,
}) => {
  const isHovered = useIsHovered()

  const variants = {
    hover: { ...mouseEnter },
    noHover: { ...mouseLeave },
  }
  return (
    <MotionBox
      variants={variants}
      initial={initial}
      animate={isHovered ? "hover" : "noHover"}
      transition={transition}
    >
      {children}
    </MotionBox>
  )
}
